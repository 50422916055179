import firebase from 'firebase';

/* firebase DEVELOP credential */
// const firebaseApp = firebase.initializeApp({
//   apiKey: "AIzaSyD9hZqi6sWDxv9a7YxjF6iIUH54eHU2YII",
//   authDomain: "soluna-firebase.firebaseapp.com",
//   databaseURL: "https://soluna-firebase.firebaseio.com",
//   projectId: "soluna-firebase",
//   storageBucket: "soluna-firebase.appspot.com",
//   messagingSenderId: "70504632568",
//   appId: "1:70504632568:web:731daad3b641804a7aab0d"
// });

/* firebase PRODUCTION credential */
const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA6zHlAk86qR2P_3HQ1oNjcrv7eaM_g4Lk",
  authDomain: "soluna-production.firebaseapp.com",
  databaseURL: "https://soluna-production.firebaseio.com",
  projectId: "soluna-production",
  storageBucket: "soluna-production.appspot.com",
  messagingSenderId: "573208420306",
  appId: "1:573208420306:web:f43b09f296155d85fd720b",
  measurementId: "G-N6T8G68PGF"
});

/* use this to get comments on a post on share page */
const db = firebaseApp.firestore();

export { db };

// gets posts using Post ID
export const getPost = async (postID) => {
  // DEVELOP
  // const origin = 'https://us-central1-soluna-firebase.cloudfunctions.net';
  // PRODUCTION
  const origin = 'https://us-central1-soluna-production.cloudfunctions.net';
  const url = `${origin}/api/posts/${postID}`;
  try {
    return await fetch(url)
    .then(res => res.json())
    .then(payload => {
      if (payload && payload.data) {
        return payload.data;
      }
    });
  } catch (error) {
    // TODO: implement error handler
    console.log(error)
  }
  return null;
}

// gets posts using Post ID
export const getGift = async (giftID) => {
  // DEVELOP
  // const origin = 'https://us-central1-soluna-firebase.cloudfunctions.net';
  // PRODUCTION
  const origin = 'https://us-central1-soluna-production.cloudfunctions.net';
  const url = `${origin}/api/gifts/${giftID}`;
  try {
    return await fetch(url)
    .then(res => res.json())
    .then(payload => {
      if (payload && payload.data) {
        return payload.data;
      }
    });
  } catch (error) {
    // TODO: implement error handler
    console.log(error)
  }
  return null;
}

// gets posts using Post ID
export const markGiftAsOpened = async (giftID) => {
  // DEVELOP
  // const origin = 'https://us-central1-soluna-firebase.cloudfunctions.net';
  // PRODUCTION
  const origin = 'https://us-central1-soluna-production.cloudfunctions.net';
  const url = `${origin}/api/gifts/${giftID}/opened`;
  try {
     await fetch(url, {
      method: 'POST'
    })
  } catch (error) {
    console.log(error)
  }
}
