import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LandingPage from './landing';
import SharePost from './share-post';
import ShareGift from './share-gift';

export const App = () => {
  return(
    <Router>
      { /* landing page */ }
      <Route exact path='/' component={LandingPage} />
      <Route path='/posts/:postID' component={SharePost} />
      <Route path='/gifts/:giftID' component={ShareGift} />
    </Router>
  );
}

export default App;
