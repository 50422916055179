import React from 'react';
import styled, { keyframes } from 'styled-components';
import wordmark from './wordmark.svg';
import logo from './logo.svg';

/* wrap the entire page */
const LandingPageWrapper = styled.div`
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
`;
LandingPageWrapper.displayName = 'LandingPageWrapper';

/* contains wordmark at the top of the page */
const PageHeader = styled.header`
  height: 9vh;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
`;
PageHeader.displayName = 'PageHeader';

/* WeShare wordmark in upper left corner */
const Wordmark = styled.img`
  position: relative;
  top: 0;
  left: 2.6rem;
  height: 3rem;
  width: 6rem;
  min-height: 3rem;
  min-width: 6rem;
`;
Wordmark.displayName = 'Wordmark';

/* contains logo */
const LandingPageBody = styled.header`
  background-color: #24263d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
`;
LandingPageBody.displayName = 'LandingPageBody';

/* keyframes rotation of logo */
const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

/* circular, rotating logo of W */
const Logo = styled.img`
  height: 40vmin;
  animation: ${rotation} 200s infinite linear;
`;
Logo.displayName = 'Logo';

export const LandingPage = () => {
  return (
    <LandingPageWrapper>
      <PageHeader>
        <Wordmark src={wordmark} alt="" />
      </PageHeader>
      <LandingPageBody>
        <Logo src={logo} alt="" />
      </LandingPageBody>
    </LandingPageWrapper>
  );
}

export default LandingPage;
