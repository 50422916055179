import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import arrowIcon from './arrow-black-icon.png';

/* post image is the background of this div */
const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  width: 28.25rem;
  height: 21rem;
  overflow: hidden;
  background: url(${p => p.image});
  background-size: cover;
  background-position: center center;
  @media(max-width: 28.25rem) {
    width: 100%;
    height: 0;
    padding-bottom: 80%;
  }
`;
ImageContainer.displayName = 'ImageContainer';

/* contains image arrows */
const PostImageArrowContainer = styled.div`
  padding: 0 1rem;
  position: relative;
  top: calc(${p => p.imageContainerHeight}px / 2 - 16px);
  height: 1rem;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;
PostImageArrowContainer.displayName = 'PostImageArrowContainer';

/* left image arrow */
const PostImageLeftArrow = styled.img`
  height: 1.4rem;
  width: 1.4rem;
  cursor: pointer;
  ${p => !p.firstIndex} {
    height: 0;
    width: 0;
  }
`;
PostImageLeftArrow.displayName = 'PostImageLeftArrow';

/* right image arrow */
const PostImageRightArrow = styled.img`
  height: 1.4rem;
  width: 1.4rem;
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  cursor: pointer;
`;
PostImageRightArrow.displayName = 'PostImageRightArrow';

/* contains image pagination indicators */
const PostImagePaginationContainer = styled.div`
  margin-top: 0.6rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;
PostImagePaginationContainer.displayName = 'PostImagePaginationContainer';

/* single page indicator dot */
const PostImagePaginationDot = styled.div`
  margin: 0 0.1rem;
  border-radius: 6px;
  height: 6px;
  width: 6px;
  background-color: #C4C4C4;

  ${p => p.current} {
    background-color: #2B2B2B;
  }
`;
PostImagePaginationDot.displayName = 'PostImagePaginationDot';

class PostImageContainer extends Component {
  constructor() {
    super();
    this.state = {
      imageContainerHeight: 0,
    };
  }

  /* dynamically place image pagination arrows based on image container height */
  updateImageArrowPosition = () => {
    const imageContainer = document.getElementById('image-container');
    const imageContainerHeight = imageContainer ? imageContainer.offsetHeight : 0;
    if (imageContainerHeight !== this.state.imageContainerHeight) {
      this.setState({
        imageContainerHeight: imageContainerHeight,
      });
    }
  }

  componentDidMount() {
    /* position image pagination arrows for the first time */
    this.updateImageArrowPosition();

    /* add event listener for window resize to re-position image pagination arrows */
    window.addEventListener('resize', this.updateImageArrowPosition);
  }

  /* remove event listener */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateImageArrowPosition);
  }

  render() {
    const {images, imageIndex, changeImageIndex} = this.props;
    const {imageContainerHeight } = this.state;
    return (
      <Fragment>
        <ImageContainer id='image-container' image={images[imageIndex]} imageIndex={imageIndex}>
        {images && images.length > 1 && (
          <PostImageArrowContainer imageContainerHeight={imageContainerHeight}>
            {imageIndex === 0 && (
              <PostImageLeftArrow
                src={arrowIcon}
                onClick={() => changeImageIndex(-1)}
                firstIndex={true.toString()}
              />
            )}
            {imageIndex !== 0 && (
              <PostImageLeftArrow
                src={arrowIcon}
                onClick={() => changeImageIndex(-1)}
              />
            )}
            {imageIndex < images.length - 1 && (
              <PostImageRightArrow
                src={arrowIcon}
                onClick={() => changeImageIndex(1)}
              />
            )}
          </PostImageArrowContainer>
        )}
        </ImageContainer>
        <PostImagePaginationContainer pages={images.length}>
          {images && images.length > 1 && (
            images.map((_, index) => (
              <PostImagePaginationDot
                key={index}
                current={imageIndex !== index ? 'true' : undefined}
              />
            ))
          )}
        </PostImagePaginationContainer>
      </Fragment>
    );
  }
}

export default PostImageContainer;
