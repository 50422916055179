import React, { Component } from 'react';
import Moment from 'react-moment';
import styled, { keyframes } from 'styled-components';
import { db, getPost } from './firebase/firebase-api';
import PostImageContainer from './post-image-container';
import wordmark from './wordmark-dark.svg';
import logo from './logo.svg';
import shareIcon from './share-icon.png';
import clockIcon from './clock-icon.png';

/* wrap the entire page */
const PageWrapper = styled.div`
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  background-color: #F7F7F7;
`;
PageWrapper.displayName = 'PageWrapper';

/* contains share post header */
const PageHeader = styled.header`
  border-bottom: 1px solid #E8E8E8;
  height: 9vh;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #FFFFFF;
  z-index: 1;
  @media(max-width: 28.25rem) {
    height: 7vh;
  }
`;
PageHeader.displayName = 'PageHeader';

/* WeShare wordmark in upper left corner */
const Wordmark = styled.img`
  position: relative;
  top: 0;
  left: 2.6rem;
  height: 3rem;
  width: 6rem;
  min-height: 3rem;
  min-width: 6rem;
  @media(max-width: 31.25rem) {
    left: 1.6rem;
  }
  @media(max-width: 28.25rem) {
    left: 1rem;
  }
`;
Wordmark.displayName = 'Wordmark';

/* contains everything below header */
const BodyContainer = styled.div`
  margin-top: 4.5rem;
  min-height: 91vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #F7F7F7;
  @media(max-width: 28.25rem) {
    margin-top: 3rem;
    justify-content: flex-start;
  }
`;
BodyContainer.displayName = 'BodyContainer';

/* keyframes rotation of logo */
const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

/* contains loading spinner */
const LoadingContainer = styled.div`
  min-height: 91vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
LoadingContainer.displayName = 'LoadingContainer';

/* circular, rotating logo of W */
const Logo = styled.img`
  margin-bottom: 5rem;
  height: 16vmin;
  align-self: center;
  animation: ${rotation} 3s infinite linear;
`;
Logo.displayName = 'Logo';

/* contains post column in center of page */
const PostContainer = styled.div`
  border: 1px solid #E8E8E8;
  padding-bottom: 0.8rem;
  height: 100%;
  min-width: 30.25rem;
  max-width: 30.25rem;
  display: flex;
  flex-flow: column nowrap;
  background-color: #FFFFFF;
  @media(max-width: 31.25rem) {
    min-width: 20rem;
  }
  @media(max-width: 28.25rem) {
    width: 100%;
  }
`;
PostContainer.displayName = 'PostContainer';

/* contains post author facebook pic and name, and action button */
const PostHeader = styled.div`
  margin: 0.8rem 1rem;
  display: flex;
  justify-content: space-between;
`;
PostHeader.displayName = 'PostHeader';

/* contains post author facebook pic and name, and created date */
const PostOwnerContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: left;
  vertical-align: middle;
`;
PostOwnerContainer.displayName = 'PostOwnerContainer';

/* crop post author facebook pic to make it circular */
const PostOwnerAvatarContainer = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  @media(max-width: 28.25rem) {
    width: 38px;
    height: 38px;
  }
`;
PostOwnerAvatarContainer.displayName = 'PostOwnerAvatarContainer';

/* post author facebook pic */
const PostOwnerAvatar = styled.img`
  display: inline;
  height: 50px;
  width: 50px;
`;
PostOwnerAvatar.displayName = 'PostOwnerAvatar';

/* contains post author name and created date */
const PostOwnerNameAndDateContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-self: center;
`;
PostOwnerNameAndDateContainer.displayName = 'PostOwnerNameAndDateContainer';

/* post author name */
const PostOwnerName = styled.div`
  display: flex;
  text-align: left;
  @media(max-width: 28.25rem) {
    max-width: 9rem;
    font-size: 0.9rem;
  }
`;
PostOwnerName.displayName = 'PostOwnerName';

/* contains clock icon and post created date */
const PostCreatedDateContainer = styled.div`
  margin-top: 0.2rem;
  display: flex;
  flex-flow: row nowrap;
`;
PostCreatedDateContainer.displayName = 'PostCreatedDateContainer';

/* clock icon next to post created date */
const ClockIconContainer = styled.div`
  margin-right: 0.325rem;
  display: flex;
  height: 0.7rem;
  width: 0.7rem;
  align-self: center;
`;
ClockIconContainer.displayName = 'ClockIconContainer';

const ClockIcon = styled.img`
  height: 0.7rem;
  width: 0.7rem;
`;
ClockIcon.displayName = 'ClockIcon';

/* post created date - time ago format */
const PostCreatedDate = styled(Moment)`
  font-size: 0.7rem;
  color: #777777;
`;
PostCreatedDate.displayName = 'PostCreatedDate';

/* action button above post image */
const SeeItInTheAppButton = styled.button`
  margin-bottom: -0.5rem;
  border: none;
  border-radius: 4px;
  padding: 0 1rem;
  align-self: center;
  min-width: 6rem;
  height: 2.3rem;
  background-color: #24263D;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 0.925rem;
  font-weight: 500;
  :focus {
    outline: none;
  }
  @media(max-width: 28.25rem) {
    margin-bottom: -0.3rem;
    height: 2rem;
    font-size: 0.925rem;
  }
`;
SeeItInTheAppButton.displayName = 'SeeItInTheAppButton';

/* contains likes and share button, below post image */
const PostLikesAndShareContainer = styled.div`
  margin: 0.4rem 1rem 0.2rem 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;
PostLikesAndShareContainer.displayName = 'PostLikesAndShareContainer';

/* contains share button */
const PostShareContainer = styled.div`
  margin-right: 0.2rem;
  cursor: pointer;
`;
PostShareContainer.displayName = 'PostShareContainer';

/* share button icon */
const PostShareButton = styled.img`
  height: 1.2rem;
  width: auto;
`;
PostShareButton.displayName = 'PostShareButton';

/* number of likes and views below post image and above post title */
const PostLikesAndViews = styled.div`
  padding-top: 0.2rem;
  text-align: left;
  font-weight: 600;
  font-size: 0.85rem;
  color: #535353;
`;
PostLikesAndViews.displayName = 'PostLikesAndViews';

/* title of post below image */
const PostTitle = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 0.8rem;
`;
PostTitle.displayName = 'PostTitle';

/* description of post below title */
const PostDescription = styled.div`
  margin: 0.4rem 1rem;
  text-align: left;
  font-size: 0.8rem;
  color: #2B2B2B;
`;
PostDescription.displayName = 'PostDescription';

/* "post not found" */
const ErrorText = styled.div`
  font-size: 1.2rem;
`;
ErrorText.displayName = 'ErrorText';

/* contains all comments, below description */
const PostComments = styled.div`
  text-align: left;
`;
PostComments.displayName = 'PostComments';

/* single comment */
const PostComment = styled.div`
  margin: 0.4rem 1rem;
  display: block;
`;
PostComment.displayName = 'PostComment';

/* author of comment */
const PostCommentAuthor = styled.div`
  margin-right: 0.6rem;
  display: inline-block;
  text-align: left;
  font-weight: 600;
  font-size: 0.8rem;
`;
PostCommentAuthor.displayName = 'PostCommentAuthor';

/* content of comment */
const PostCommentContent = styled.div`
  margin-right: 0.6rem;
  display: inline-block;
  text-align: left;
  font-size: 0.8rem;
`;
PostCommentContent.displayName = 'PostCommentContent';

/* shows message when user copies url to clipboard */
const CopySuccess = styled.div`
  margin: -1.25rem 0 0 -6.25rem;
  border-radius: 4px;
  padding: 0.375rem 0.375rem 0.375rem 0.85rem;
  position: absolute;
  display: ${p => p.showSuccess ? "block" : "none"};
  top: 50%;
  left: 50%;
  width: 11.25rem;
  background: #24263D;
  background: ${p => p.showSuccess ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0)"};
  font-size: 0.8125rem;
  color: #FFFFFF;
  content: attr(title);
  z-index: 99;
`;
CopySuccess.displayName = 'CopySuccess';

class SharePost extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: false,
      title: '',
      description: '',
      images: [],
      userPhotoUrl: [],
      userDisplayName: '',
      actionButtonText: '',
      shareUrl: undefined,
      likes: '',
      comments: [],
      showCopySuccess: false,
      imageIndex: 0,
    }
  }

  /* get the action button text according to post type */
  getActionButtonText = (post) => {
    if (post && post.type) {
      switch (post.type) {
        case "offer": return "Download WeShare"
        case "request": return "Download WeShare"
        default: return "view in the app"
      }
    }
    return "View in the app";
  }

  /* format likes string */
  getLikesText = (likes) => {
    if (!likes) return "0 likes"
    if (likes.length === 1) return "1 like"
    else if (likes.length === 0) return "0 likes"
    else return `${likes.length} likes`
  }

  /* format views string */
  getViewsText = (views) => {
    if (!views) return "0 views"
    if (views === 1) return "1 view"
    else if (views === 0) return "0 views"
    else return `${views} views`
  }

  /* got to app store when the action button is clicked */
  openPostShareUrl = () => {
    window.open("https://apps.apple.com/us/app/weshare-free-local-items/id1459777950", "_blank")
  }

  /* copy url to clipboard */
  copyUrlToClipboard = () => {
    const url = window.location.href;
    if (!navigator.clipboard) {
      const textArea = document.createElement("textarea");
      textArea.value = url;
      textArea.style.position = "none";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        this.showCopySuccessTooltip();
      } catch (_) {
        /* did not copy successfully */
      }

      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard.writeText(url).then(() => {
      /* successfully copied */
      this.showCopySuccessTooltip();
    }, (() => {
      /* did not copy successfully */
    }));
  }

  /* briefly show 'Copied URL to clipboard✨' message */
  showCopySuccessTooltip = () => {
    this.setState({ showCopySuccess: true });
    this.hideCopySuccessTooltip();
  }

  /* hide 'Copied URL to clipboard✨' message after 1.6 seconds */
  hideCopySuccessTooltip = () => {
    setTimeout(() => {
      this.setState({ showCopySuccess: false });
    }, 1600);
  }

  /* change current image index */
  changeImageIndex = (direction) => {
    const newIndex = this.state.imageIndex + direction;
    if (newIndex < 0 || newIndex >= this.state.images.length) {
      return;
    }
    /* new index is valid, change state */
    this.setState({
      imageIndex: newIndex,
    });
  }

  async componentDidMount() {
    /* get post ID from url */
    const postID = this.props.match.params.postID;
    const post = await getPost(postID);
    if (post) {
      /* format created date */
      const seconds = post.createdAt._seconds;
      const createdDate = new Date(0);
      createdDate.setUTCSeconds(seconds);
      /* set text of action button based on post type */
      const actionButtonText = this.getActionButtonText(post);
      /* set text of likes based on number of likes */
      const likesText = this.getLikesText(post.likes);
      /* set text of views based on number of views */
      const viewsText = this.getViewsText(post.views);
      this.setState({
        loading: false,
        error: false,
        title: post.title,
        description: post.description,
        createdDate: createdDate,
        images: post.images,
        userPhotoUrl: post.userPhotoUrl,
        userDisplayName: post.userDisplayName,
        actionButtonText: actionButtonText,
        shareUrl: post.shareUrl || undefined,
        likes: likesText,
        views: viewsText,
        commentHashes: post.comments,
      });
      /* get post comments from firebase collection */
      if (post.comments) {
        post.comments.map((commentHash) => {
          db.collection("comments").doc(commentHash).get().then((doc) => (
            this.setState((prevState) => ({
              comments: [...prevState.comments, {
                ...doc.data()
              }]
            }))
          ));
        });
      }
    } else {
      /* show invalid post id error */
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  render() {
    const {
      loading,
      error,
      title,
      description,
      createdDate,
      images,
      userPhotoUrl,
      userDisplayName,
      actionButtonText,
      shareUrl,
      likes,
      views,
      commentHashes,
      comments,
      showCopySuccess,
      imageIndex,
    } = this.state;

    /* show loading spinner if post has not yet been loaded */
    if (loading) {
      return (
        <PageWrapper>
          <PageHeader>
            <Wordmark src={wordmark} alt="" />
          </PageHeader>
          <BodyContainer>
            <LoadingContainer>
              <Logo src={logo} alt="" />
            </LoadingContainer>
          </BodyContainer>
        </PageWrapper>
      );
    }

    /* show error when no post is found for this post ID */
    if (error) {
      console.log(error)
      return (
        <PageWrapper>
          <PageHeader>
            <Wordmark src={wordmark} alt="" />
          </PageHeader>
          <BodyContainer>
            <ErrorText>
              Post not found.
            </ErrorText>
          </BodyContainer>
        </PageWrapper>
      );
    }

    return (
      <PageWrapper>
        <PageHeader>
          <Wordmark src={wordmark} alt="" />
        </PageHeader>
        <BodyContainer>
          <PostContainer>
            <PostHeader>
              <PostOwnerContainer>
                <PostOwnerAvatarContainer>
                  <PostOwnerAvatar src={userPhotoUrl} />
                </PostOwnerAvatarContainer>
                <PostOwnerNameAndDateContainer>
                  <PostOwnerName>{userDisplayName}</PostOwnerName>
                  <PostCreatedDateContainer>
                    <ClockIconContainer>
                      <ClockIcon src={clockIcon} />
                    </ClockIconContainer>
                    <PostCreatedDate fromNow>
                      {createdDate}
                    </PostCreatedDate>
                  </PostCreatedDateContainer>
                </PostOwnerNameAndDateContainer>
              </PostOwnerContainer>
              <SeeItInTheAppButton onClick={() => this.openPostShareUrl(shareUrl)}>
                {actionButtonText}
              </SeeItInTheAppButton>
            </PostHeader>
            <PostImageContainer
              images={images}
              imageIndex={imageIndex}
              changeImageIndex={this.changeImageIndex}
            />
            <PostLikesAndShareContainer>
              <PostTitle>{title}</PostTitle>
              <PostShareContainer onClick={this.copyUrlToClipboard}>
                <CopySuccess showSuccess={showCopySuccess}>
                  Copied URL to clipboard✨
                </CopySuccess>
                <PostShareButton src={shareIcon}/>
              </PostShareContainer>
            </PostLikesAndShareContainer>
            <PostDescription>{description}</PostDescription>
            <PostComments>
              {comments &&
                comments.map((comment, index) => {
                  return (
                    <PostComment key={commentHashes[index]}>
                      <PostCommentAuthor>{comment.userDisplayName}</PostCommentAuthor>
                      <PostCommentContent>{comment.content}</PostCommentContent>
                    </PostComment>
                  )
                })
              }
            </PostComments>
          </PostContainer>
        </BodyContainer>
      </PageWrapper>
    );
  }
}

export default SharePost;
